<template>
    <section v-show="id_pedido">
        <div v-if="pedido.id_pedido != null" class="border-bottom p-2">
            <div class="row mx-0 my-2 align-items-center">
                <div class="col-auto px-3" />
                <p class="col f-600 f-17 text-general">{{ $config.cliente }}</p>
            </div>
            <div class="row mx-0 align-items-center my-2">
                <img class="border rounded-circle bg-whitesmoke" :src="pedido.datos_pedido.avatar ? pedido.datos_pedido.avatar : '/img/no-imagen/sin_user.png'" width="40" height="40" alt="" />
                <p class="mx-2 text-general f-14">{{ pedido.datos_pedido.nombre }}</p>
                <div class="br-20  bg-light-f5 text-general ml-auto mx-2">
                    <span class="mx-2">{{ pedido.datos_pedido.cant_pedidos }}</span>
                    <i class="f-20 icon-pedidos" />
                </div>
            </div>

            <div class="row mx-0 my-2 align-items-center">
                <el-tooltip placement="bottom" content="Fecha Entrega" effect="light">
                    <i class="icon-calendar-clock text-general f-22 mr-2" />
                </el-tooltip>
                <p v-if="pedido.uso_horario === 1" class="col f-15 text-general">{{ pedido.fecha_entrega | helper-fecha('ddd DD MMMM hh:mm a') }}</p>
                <p v-else class="col f-15 text-general">No programado</p>
            </div>
            <div class="row mx-0 my-2">
                <div class="col-auto pl-0 pr-2">
                    <el-tooltip placement="bottom" content="Dirección" effect="light">
                        <i class="icon-location text-general f-20" />
                    </el-tooltip>
                </div>
                <div class="col col f-15 text-general">
                    <p v-html="formatearTextoHtml(pedido.datos_pedido.direccion)" />
                    <p class="mt-2">{{ pedido.distancia }} Mts Aprox.</p>
                </div>
            </div>
            <div class="row mx-0 my-2 align-items-center">
                <i class="icon-phone text-general f-22 mr-2" />
                <p class="col f-15 text-general">{{ pedido.datos_pedido.telefono || 'No registra' }}</p>
            </div>
            <div class="row mx-0 my-2 align-items-center">
                <i class="icon-identification f-16 text-general mr-3" />
                <p class="col f-15 text-general">{{ pedido.datos_pedido.identificacion || 'No registra' }}</p>
            </div>
        </div>
        <div v-if="pedido.id_pedido == null" class="border-bottom">
            <div class="row mx-auto text-general f-14 justify-center my-2">
                <span class="f-500">{{ $config.cliente }} del {{ $config.vendedor }}</span>
            </div>
            <div class="row mx-0 my-2 px-2 align-items-center">
                <i class="icon-cliente text-general f-20 mr-2" />
                <p class="col f-14 text-general">{{ pedido.digitado_cliente_nombre || 'No registra' }}</p>
                <div class="br-20 px-2 mx-2 bg-light-f5 text-general">
                    <span class="mx-2">0</span>
                    <i class="f-20 icon-pedidos" />
                </div>
            </div>
            <div class="row mx-0 my-2 px-2 align-items-center">
                <i class="icon-phone text-general f-20 mr-2" />
                <p class="col f-14 text-general">{{ pedido.digitado_cliente_telefono || 'No registra' }}</p>
            </div>
        </div>
        <!-- Atiende -->
        <div class="border-bottom p-2">
            <div class="row mx-0 my-2 align-items-center">
                <i class="icon-storefront-outline text-general f-22 mr-2" />
                <p class="col pl-0 f-17 f-500 text-general">Atiende</p>
            </div>
            <div class="row mx-0 align-items-center my-2">
                <div class="col-auto px-3 mr-1" />
                <img class="border br-10 bg-whitesmoke" :src="cliente.logo_mini ? cliente.logo_mini : '/img/no-imagen/sin_user.png'" width="45" height="45" alt="" />
                <div class="col">
                    <p class="f-15 f-500 text-general">{{ cliente.nombre }}</p>
                    <p class="f-15  text-general">Cedi:{{ cliente.cedis }}</p>
                </div>
                <div class="br-20 mx-2 bg-light-f5 text-general">
                    <span class="mx-2">{{ cliente.cant_pedidos }}</span>
                    <i class="f-20 icon-pedidos" />
                </div>
            </div>
            <div class="row mx-0 my-2 align-items-center">
                <el-tooltip placement="bottom" content="Fecha Entrega" effect="light">
                    <i class="icon-calendar-clock text-general f-20 mr-2" />
                </el-tooltip>
                <p v-if="pedido.uso_horario === 1" class="col pl-0 f-15 text-general">{{ pedido.fecha_entrega | helper-fecha('DD MMM YYYY hh:mm a') }}</p>
                <p v-else class="col pl-0 f-15 text-general">No programado</p>
            </div>
            <div class="row mx-0 my-2">
                <i class="icon-location text-general f-20 mr-2" />
                <div class="col pl-0">
                    <p class="f-500 f-17 text-general">Dirección de domicilio</p>
                    <p class="f-15 text-general" v-html="formatearTextoHtml(cliente.direccion)" />
                </div>
            </div>
            <div class="row mx-0 my-2 align-items-center text-general">
                <i class="icon-phone text-general f-20 mr-2" />
                <p class="col pl-0 f-15 text-general">{{ cliente.telefono_celular }}</p>
            </div>

            <div v-show="pedido.estado != 1" class="row mx-0 my-2 align-items-center">
                <el-tooltip placement="bottom" content="Valor Domicilio" effect="light">
                    <i class="icon-truck-fast-outline text-general f-18 mr-2" />
                </el-tooltip>
                <p class="col pl-0 f-15 text-general">{{ convertMoney(pedido.valor_envio, pedido.idm_moneda) }}</p>
            </div>
            <div class="row mx-0 my-2 align-items-center">
                <i class="icon-creditos f-15 text-general mr-2" />
                <p class="col pl-2 f-500 f-15 text-general">Créditos</p>
            </div>
            <div class="row ml-4 pl-2 mr-0 align-items-center">
                <el-tooltip placement="top" content="Deuda Vigente" effect="light">
                    <i class="icon-clock text-gr-general f-18" />
                </el-tooltip>
                <span class="f-14 mr-2">{{ convertMoney(pedido.saldo_vigente,pedido.idm_moneda) }}</span>

                <el-tooltip placement="top" content="Deuda Vencida" effect="light">
                    <i class="icon-attention-alt text-danger f-18" />
                </el-tooltip>
                <span class="f-14">{{ convertMoney(pedido.saldo_vencido,pedido.idm_moneda) }}</span>
            </div>
            <div class="row mx-0 my-2 align-items-center">
                <i class="icon-playlist-check f-20 text-general mr-2" />
                <p class="col pl-0 f-500 f-15 text-general">Alianza {{ $config.vendedor }}</p>
            </div>
            <div class="row mx-0 mb-2  text-general align-items-center">
                <div class="col-auto px-2" />
                <div class="col-10">
                    <p class="f-13 text-center">Cambie la condición del {{ $config.vendedor }}</p>
                    <el-select v-model="pedido.id_condicion" :disabled="!$can('botones_abastecimiento_condicion_pedido')" size="medium" class="w-100" placeholder="Seleccione condición" @change="setCondicion">
                        <el-option
                        v-for="item in condiciones"
                        :key="item.id"
                        :label="item.condicion"
                        :value="item.id"
                        />
                    </el-select>
                </div>
            </div>
        </div>
        <!-- Atiende -->

        <div class="border-bottom text-general p-2">
            <div class="row mx-0 my-2 align-items-center">
                <div class="col-auto pl-0 pr-2">
                    <i class="icon-shopping-outline f-22" />
                </div>
                <p class="col f-600 f-17 pl-0 text-general">Picking & Packing</p>
            </div>
            <div class="row mx-0 mt-3 text-general align-items-center">
                <div class="col-auto px-3 mr-1" />
                <div class="col px-0 d-flex">
                    <div class="bg-general2 border mr-2 br-5 d-middle-center" style="width:50px;height:50px;">
                        <img v-if="empacador.foto != null" class="border br-5 bg-whitesmoke" :src="empacador.foto" width="100%" height="100%" />
                        <i v-else class="icon-shopping-outline f-25 py-2 px-1 text-white" />
                    </div>
                    <div class="col px-0">
                        <p class="text-general2 f-14 f-500">Empacador</p>
                        <el-select
                        v-if="!empacador.id"
                        v-model="pedido.id_user_picking"
                        :disabled="!$can('botones_abastecimiento_gestionar_envio')"
                        size="medium"
                        class="w-100 select-transparent"
                        placeholder="Seleccionar Picking"
                        remote
                        @change="setEmpacador"
                        @visible-change="listarEmpacadores"
                        >
                            <el-option
                            v-for="item in empacadores"
                            :key="item.id"
                            :label="item.nombre"
                            :value="item.id"
                            />
                        </el-select>
                        <p v-else class="f-14 f-500 cr-pointer" @click="listarEmpacadores('asignado')">{{ empacador.nombre }}</p>
                    </div>
                </div>
            </div>
        </div>





        <!-- Delivery -->
        <div v-if="pedido.operacion_logistica_cedi != 2" class="border-bottom text-general p-2">
            <div class="row mx-0 my-2 align-items-center">
                <div class="col-auto pl-0 pr-2">
                    <i class="icon-moped f-22" />
                </div>
                <p class="col f-600 f-17 pl-0 text-general">Confirmación de Entrega</p>
            </div>
            <div class="row mx-0 mt-3 text-general align-items-center">
                <div class="col-auto px-3 mr-1" />
                <div v-if="pedido.entrega === 0" class="col px-0 d-flex">
                    <el-tooltip placement="top" content="Domiciliario" effect="light">
                        <div class="bg-general2 border mr-2 br-5 d-middle-center" style="width:50px;height:50px;">
                            <img v-if="datosDelivery.foto != null" class="border br-5 bg-whitesmoke" :src="datosDelivery.foto" width="100%" height="100%" />
                            <i v-else class="icon-moped f-25 py-2 px-1 text-white" />
                        </div>
                    </el-tooltip>
                    <div class="col px-0">
                        <p class="text-general2 f-14 f-500">Delivery</p>
                        <el-select
                        v-model="pedido.id_user_delivery"
                        :disabled="!$can('botones_abastecimiento_gestionar_envio')"
                        size="small"
                        class="w-100 select-transparent"
                        placeholder="Seleccionar delivery"
                        @change="setDelivery"
                        >
                            <el-option
                            v-for="item in deliverys"
                            :key="item.id"
                            :label="item.nombre"
                            :value="item.id"
                            />
                        </el-select>
                    </div>
                </div>
                <div v-if="pedido.entrega" class="col-12 f-13">
                    <div class="col d-flex">
                        <div class="bg-general2 border mr-2 br-5 d-middle-center" style="width:50px;height:50px;">
                            <img class="border br-5 bg-whitesmoke" :src="entrega.foto_delivery" width="100%" height="100%" />
                        </div>
                        <div class="col px-0">
                            <p class="text-general2 f-14 f-500">Delivery</p>
                            <p>{{ entrega.nombre_delivery }}</p>
                        </div>
                    </div>
                    <div class="col mt-2">
                        <p>Delivery llegó y confirmo</p>
                        <p>{{ entrega.created_at | helper-fecha('DD-MMM hh:mm a') }}</p>
                    </div>
                    <div class="col pt-2">
                        <p>"Cliente" confirmo entrega</p>
                        <p>{{ entrega.momento_cliente | helper-fecha('DD-MMM hh:mm a') }}</p>
                    </div>
                    <div class="col pt-2">
                        <p>Delivery recibio</p>
                        <p>{{ entrega.momento_delivery | helper-fecha('DD-MMM hh:mm a') }}</p>
                    </div>
                </div>
            </div>
            <div v-if="pedido.id_pedido != null" class="row mx-0 mt-3 justify-content-around">
                <div class="col d-flex px-2">
                    <input v-model="pedido.cliente_entrega_directa" :disabled="!$can('botones_abastecimiento_gestionar_envio')" :value="1" type="radio" class="option-input black radio" @change="cambiarDestinatario(1)" />
                    <span class="f-14 f-500" :class="pedido.cliente_entrega_directa ? 'text-general' : 'text-general2'">Enviar al {{ $config.cliente }}</span>
                </div>
                <div class="col d-flex px-2">
                    <input v-model="pedido.cliente_entrega_directa" :disabled="!$can('botones_abastecimiento_gestionar_envio')" :value="0" type="radio" class="option-input black radio" @change="cambiarDestinatario(0)" />
                    <span class="f-14 f-500" :class="!pedido.cliente_entrega_directa ? 'text-general' : 'text-general2'">Enviar al {{ $config.vendedor }}</span>
                </div>
            </div>
            <div class="row mx-0 mt-3 mb-2 text-general align-items-center">
                <div class="col-auto px-2">
                    <el-checkbox v-model="pedido.otra_direccion" :disabled="!$can('botones_abastecimiento_gestionar_envio')" :true-label="1" :false-label="0" class="check-red" @change="nueva_direccion" />
                </div>
                <div class="col px-2 f-13" :class="pedido.otra_direccion ? 'text-general' : 'text-general2'">
                    Este Pedido se enviara a una dirección diferente a la registrada.
                </div>
            </div>
            <div class="row ml-4 mt-3">
                <div v-if="pedido.envio_paqueteria && pedido.guias.length == 0" class="col-12 mb-3">
                    <button class="btn br-10 bg-general text-white p-2 f-14" @click="goToFormOrder">
                        <i class="icon-truck-fast-outline mr-1" />
                        Enviar por paqueteria
                    </button>
                </div>
                <div v-else-if="pedido.envio_paqueteria && pedido.guias.length > 0" class="col-12 mb-3">
                    <button class="btn border br-10 p-2 f-14 text-black2" @click="openModalguias(pedido.guias)">
                        <!-- <a href="https://www.servientrega.com/" target="_blank"> -->
                        <i class="icon-truck-fast-outline mr-1" />
                        Rastrear pédido
                        <!-- </a> -->
                    </button>
                </div>
            </div>
        </div>
        <!-- Delivery -->
        <!-- Inicio operador logístico externo -->
        <div v-else class="border-bottom text-general p-2">
            <div class="row mx-0 my-2 align-items-center">
                <div class="col-auto pl-0 pr-2">
                    <i class="icon-moped f-22" />
                </div>
                <p class="col f-600 f-17 pl-0 text-general">Operador logístico externo</p>
            </div>
            <div v-if="pedido.operador_logistico_pedido === 0" class="row mx-0 mt-3 ml-4 mr-4 text-general align-items-center">
                <div class="col-5 px-3 mr-1">
                    <div type="button" class="bg-general text-white br-8 px-5 shadow py-1 cr-pointer h-32 d-middle-center" @click="confirmarAsignar">
                        Asignar
                    </div>
                </div>
            </div>
            <div v-else class="row mx-0 mt-1 ml-4 mr-4 text-general align-items-center">
                <div class="col-12 px-3 mr-1">
                    <div class="mx-0 align-items-center">
                        <p class="f-14">{{ comentarioOperadorAsignado() }}</p>
                    </div>
                </div>
                <div class="col-5 px-3 mr-1">
                    <div type="button" class="btn-outline br-8 px-5 shadow py-1 cr-pointer h-32 d-middle-center mt-2" @click="confirmarDesvincular">
                        Desvincular
                    </div>
                </div>
            </div>
            <div v-if="pedido.id_pedido != null" class="row mx-0 mt-3 justify-content-around">
                <div class="col d-flex px-2">
                    <input v-model="pedido.cliente_entrega_directa" :disabled="!$can('botones_abastecimiento_gestionar_envio')" :value="1" type="radio" class="option-input black radio" @change="cambiarDestinatario(1)" />
                    <span class="f-14 f-500" :class="pedido.cliente_entrega_directa ? 'text-general' : 'text-general2'">Enviar al Cliente</span>
                </div>
                <div class="col d-flex px-2">
                    <input v-model="pedido.cliente_entrega_directa" :disabled="!$can('botones_abastecimiento_gestionar_envio')" :value="0" type="radio" class="option-input black radio" @change="cambiarDestinatario(0)" />
                    <span class="f-14 f-500" :class="!pedido.cliente_entrega_directa ? 'text-general' : 'text-general2'">Enviar al {{ $config.vendedor }}</span>
                </div>
            </div>
            <div class="row mx-0 mt-3 mb-2 text-general align-items-center">
                <div class="col-auto px-2">
                    <el-checkbox v-model="pedido.otra_direccion" :disabled="!$can('botones_abastecimiento_gestionar_envio')" :true-label="1" :false-label="0" class="check-red" @change="nueva_direccion" />
                </div>
                <div class="col px-2 f-13" :class="pedido.otra_direccion ? 'text-general' : 'text-general2'">
                    Este Pedido se enviara a una dirección diferente a la registrada.
                </div>
            </div>
        </div>
        <!-- Fin operador logístico externo -->
        <!-- Inicio convenio nómina -->
        <div v-if="convenio != null" class="border-bottom text-general p-2">
            <div class="row mx-0 my-2 align-items-center">
                <div class="col-auto pl-0 pr-2">
                    <i class="icon-convenio f-22" />
                </div>
                <p class="col f-600 f-17 pl-0 text-general">Convenio de nómina</p>
            </div>
            <div class="row mx-0 align-items-center my-2">
                <div class="col-auto px-3 mr-1" />
                <img class="border br-10 bg-whitesmoke obj-cover" :src="convenio.convenio_logo != null ? convenio.convenio_logo : '/img/no-imagen/no-imagen-empresa.svg'" width="45" height="45" alt="" />
                <div class="col">
                    <p class="f-15 f-500 text-general">{{ convenio.convenio }}</p>
                    <p class="f-15" :style="convenio.icon === 'bien' ? 'color:#29D884' : 'color:#FF5806;'">
                        <i v-if="convenio.icon === 'bien'" class="icon-ok-circled-outline f-18" style="color:#29D884;" />
                        <i v-else class="icon-attention-alt f-18" style="color:#FF5806;" />
                        {{ convenio.convenio_estado }}
                    </p>
                </div>
            </div>
            <div class="row mx-0 align-items-center my-2">
                <div class="col-auto px-3 mr-1" />
                <div class="col">
                    <p class="f-15  text-general">
                        <el-checkbox v-model="convenio.convenio_efectivo" class="check-green" :disabled="convenio.check_disabled" @change="checkConvenioNomina($event)">
                            Cobrar contraentrega
                        </el-checkbox>
                    </p>
                </div>
            </div>
        </div>
        <!-- Fin convenio nómina -->
        <div v-if="pedido.pago_teorico == 4" class="border-bottom text-general p-2">
            <div class="row mx-0 my-2 align-items-center">
                <div class="col-auto pl-0 pr-2">
                    <i class="icon-convenio f-22" />
                </div>
                <p class="col f-600 f-17 pl-0 text-general">Pago en Linea</p>
            </div>
            <div class="row mx-0 align-items-center my-2">
                <div class="col d-middle">
                    <div class="">
                        <i v-if="pagoOnline.estado == 'Aprobado'" class="icon-ok-circled-outline f-22 text-success" />
                        <i v-else class="icon-clock f-22 text-warning" />
                        <span class="f-15 f-500 text-general">{{ pagoOnline.estado }}</span>
                    </div>
                    <span class="f-15 ml-auto text-general">No. {{ pagoOnline.transaccion }}</span>
                </div>
            </div>
            <!-- <div v-if="pagoOnline.estado == 4" class="row mx-0 align-items-center my-2">
                <div class="col d-middle">
                    <div>
                        <span class="f-15 text-general"><strong>Cuenta:</strong> {{ pagoOnline.cuenta }}</span>
                    </div>
                </div>
            </div> -->
        </div>
        <!-- Inicio forma de pago -->
        <div class="border-bottom text-general p-2">
            <div class="row mx-0 my-2 align-items-center">
                <div class="col-auto pl-0 pr-2">
                    <i class="icon-convenio f-22" />
                </div>
                <p class="col f-600 f-17 pl-0 text-general">Forma de Pago</p>
            </div>
            <div class="row mx-0" :class="{'disabled-div':!$can('botones_abastecimiento_gestionar_forma_pago')}">
                <div v-for="(mp,key) in metodosPago2" :key="`rdio-${key}`" class="col-auto mx-0 my-2 f-14">
                    <div class="col d-flex px-2">
                        <input v-model="formaPago" :value="mp.nombre" :disabled="Boolean(mp.disabled)" type="radio" class="option-input black radio" @change="putFormaPagoPedido(mp)" />
                        <span class="text-general">{{ mp.nombre }}</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- Fin forma de pago -->
        <!-- linea de tiempo -->
        <div class="border-bottom py-2">
            <div class="row mx-0 justify-center mb-3">
                <div v-for="(act, index) in historial" :key="index" class="px-0 d-middle" style="width:49px;">
                    <div class="rounded-circle position-relative" :class="act.activo?'border-black':'bg-gris'" style="width:40px;height:40px;padding:1px">
                        <div class="w-100 h-100 rounded-circle d-middle-center bg-white">
                            <i :class="`f-20 icon-action ${act.icon} ${act.activo?'text-general':'text-gris'}`" />
                        </div>
                    </div>
                    <div v-show="index != historial.length-1" class="w-20" :class="act.activo?'border-black':'bg-gris'" style="height:2px" />
                </div>
            </div>

            <p class="text-general f-15 f-500 text-center">
                {{ nombreEstado }}
            </p>
            <template v-if="showLineaTiempo">
                <div v-for="(act, index) in historial" :key="index" class="row mx-0 my-3">
                    <div class="col-auto">
                        <div class="rounded-circle position-relative" :class="act.activo?'border-black':'bg-gris'" style="width:20px;height:20px;padding:1px">
                            <div class="w-100 h-100 rounded-circle d-middle-center bg-white">
                                <i v-if="act.activo" :class="`f-20 icon-ok-circled ${act.activo?'text-general':'text-gris'}`" />
                            </div>
                        </div>
                        <div v-show="index != historial.length-1" class="mx-auto h-100 py-2" :class="act.activo?'border-black':'bg-gris'" style="width:2px;" />
                    </div>
                    <div class="col">
                        <div class="row mx-0">
                            <div class="col px-1">
                                <p class="f-15" :class="act.activo?'text-general':'text-gris'">{{ act.content }}</p>
                                <p class="f-13">{{ act.hora | helper-fecha('hh:mm a DD MMM') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <p class="text-general cr-pointer f-15 text-center my-1" @click="showLineaTiempo = !showLineaTiempo">
                {{ showLineaTiempo ? 'Ver menos' : 'Ver más' }}   <i :class="`${showLineaTiempo ? 'icon-angle-up' : 'icon-angle-down'} f-18`" />
            </p>
        </div>
        <div class="border-bottom p-2">
            <div class="row mx-0 cr-pointer">
                <div class="col-auto pr-2 pl-0">
                    <i class="icon-pedidos f-22 text-general" />
                </div>
                <div class="col px-0 my-auto text-general f-17 f-500">
                    Más información
                </div>
            </div>
            <div class="row mx-0 cr-pointer">
                <div class="col-auto" />
                <div class="col f-15">
                    <div class="row mx-0 justify-content-between">
                        <p class="text-general">No. Admin Pedido</p>
                        <p class="text-general">{{ pedido.id }}</p>
                    </div>
                    <div class="row mx-0 justify-content-between">
                        <p class="text-general">No. Pedido</p>
                        <p class="text-general">{{ pedido.id_pedido === null ? '---' : pedido.id_pedido }}</p>
                    </div>
                    <div class="row mx-0 justify-content-between">
                        <p class="text-general">Fecha del Pedido</p>
                        <p v-if="pedido.uso_horario === 1" class="text-general">{{ pedido.fecha_entrega | helper-fecha('DD MMM YYYY hh:mm a') }}</p>
                        <p v-else class="text-general">No programado</p>
                    </div>
                    <div class="row mx-0 justify-content-between">
                        <p class="text-general">No. Factura</p>
                        <div class="d-flex align-items-center">
                            <p v-if="!editNumFactura" class="text-general ps-auto mb-0">{{ pedido.numero_factura === null ? '' : pedido.numero_factura }}</p>
                            <el-input v-else v-model="pedido.numero_factura" @change="editNumeroFactura" />
                            <i class="f-20 icon-pencil-outline" @click="editNumFactura = true" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 mb-2">
                <div class="col-auto" />
                <div class="col px-2">
                    <p class="text-general f-16 f-500 mt-4">Instrucciones</p>
                    <p class="text-general f-14" v-html="fun_caracteres(pedido.instrucciones)" />
                </div>
            </div>
            <div v-show="pedido.instrucciones && pedido.instrucciones.length > 300 && ver_mas == false" class="row mx-0 px-1 mt-1">
                <div class="col f-14 text-general2 f-500 cr-pointer text-right" @click="ver_mas = true">
                    Ver mas...
                </div>
            </div>
        </div>

        <div class="p-2">
            <div class="row mx-0">
                <el-select v-model="impuestos" class="w-100 mx-4" @change="openModalSetImpuestos">
                    <el-option label="Ticket sin Impuestos" :value="0" />
                    <el-option label="Ticket Valores +IVA" :value="1" />
                    <el-option label="Ticket IVA Incluido" :value="2" />
                    <el-option label="Ticket IVA incluido sin etiqueta" :value="3" />
                </el-select>
            </div>
        </div>
        <!-- Detalle cobro -->
        <div class="p-2 text-general" :class="pedido.estado != 1 ? 'border-bottom' : '' ">
            <div class="row mx-0 align-items-center">
                <i class="icon-comision f-18" />
                <p class="text-general col f-600">Detalle del Cobro</p>
            </div>
            <div class="row mx-0">
                <div class="col-auto" />
                <div class="col f-15">
                    <div class="row mx-0  justify-content-between">
                        <p>Costo de productos ({{ resumen.cantidad_productos }})</p>
                        <p class="f-500">{{ convertMoney(resumen.val_productos,pedido.idm_moneda) }}</p>
                    </div>
                    <div class="row mx-0 text-general justify-content-between">
                        <p>Descuento</p>
                        <p class="f-500 text-general">
                            {{ convertMoney(resumen.val_descuentos,pedido.idm_moneda) }}
                        </p>
                    </div>
                    <div v-if="resumen.id_cupon" class="row mx-0 justify-content-between">
                        <div class="d-middle">
                            <i class="icon-cupon pr-1" />
                            <p>{{ resumen.codigo_cupon }}</p>
                        </div>
                        <p class="text-general-red f-500">
                            {{ convertMoney(resumen.val_cupon, pedido.idm_moneda) }}
                        </p>
                    </div>
                    <div v-show="convenio" class="row mx-0 text-general justify-content-between">
                        <p>Descuento Nomina</p>
                        <p class="f-500 text-general-red">
                            {{ convertMoney(resumen.val_nomina,pedido.idm_moneda) }}
                        </p>
                    </div>
                    <div class="row mx-0 justify-content-between">
                        <p>Alianza {{ $config.vendedor }}</p>
                        <b class="text-general f-500">
                            {{ convertMoney(resumen.val_condicion,pedido.idm_moneda) }}
                        </b>
                    </div>
                    <div v-if="resumen.val_descuento_lista > 0" class="row mx-0 justify-content-between">
                        <p>Descuento Lista</p>
                        <b class="text-general f-500">
                            {{ convertMoney(resumen.val_descuento_lista,pedido.idm_moneda) }}
                        </b>
                    </div>
                    <div class="row mx-0 py-2 f-600 justify-content-between border-top">
                        <p>Sub Total</p>
                        <p>{{ convertMoney(resumen.subtotal,pedido.idm_moneda) }}</p>
                    </div>
                    <div class="row mx-0 text-general justify-content-between">
                        <p>Impuestos</p>
                        <p class="f-500 text-general">
                            {{ convertMoney(resumen.val_impuestos, pedido.idm_moneda) }}
                        </p>
                    </div>
                    <div class="row mx-0 justify-content-between">
                        <p>Costo de envío</p>
                        <p class="f-500">{{ convertMoney(resumen.val_domicilios,pedido.idm_moneda) }}</p>
                    </div>
                    <div class="row mx-0 mt-2 f-600 justify-content-between border-top pt-2">
                        <p>Total a pagar</p>
                        <p>{{ convertMoney(resumen.val_total,pedido.idm_moneda) }}</p>
                    </div>
                    <div v-show="resumen.ganancia > 0" class="row mx-0 my-2 ticket br-8 py-1">
                        <div class="col pl-1 px-0 text-general f-15">
                            Ganancias {{ $config.vendedor }}
                        </div>
                        <div class="col-auto ml-auto total-value">
                            {{ convertMoney(resumen.ganancia, pedido.idm_moneda) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Responsable cedis -->
        <div class="border-bottom p-2">
            <div v-show="pedido.estado != 1" class="row mx-0 my-2 align-items-center">
                <div class="col-auto pl-0 pr-2">
                    <i class="icon-cedis f-22 text-general" />
                </div>
                <p class="col pl-0 f-600 f-17 text-general">Responsable CEDIS</p>
            </div>
            <div v-show="pedido.estado != 1" class="row mx-0 align-items-center my-2">
                <div class="col-auto px-3 mr-2" />
                <img
                class="border br-10 bg-whitesmoke obj-cover"
                :src="pedido.foto_responsable ? pedido.foto_responsable : '/img/no-imagen/sin_user.png'"
                width="49" height="49"
                />
                <div class="col px-2 f-15">
                    <p class="text-general f-15 f-500">
                        {{ pedido.responsable || 'Sin Asignar' }}
                        <br />
                    </p>
                    <p class="text-general f-15">Operario responsable</p>
                </div>
            </div>
        </div>
        <div v-show="pedido.estado === 3 && pedido.pago_estado === 1 && $can('botones_abastecimiento_otorgar_credito')" class="row mx-0 justify-center px-2 mt-3">
            <div class="col-auto px-0">
                <div class="bg-general shadow px-4 text-white cr-pointer br-8 py-1 text-center" @click="otorgarCredito()">
                    Otorgar Credito
                </div>
            </div>
        </div>
        <modalEditarPedido ref="modalEditarPedido" />
        <modalOtorgarCredito ref="OtorgarCredito" @credito="mostar_credito" />
        <modalSiNo
        ref="modalSetImpuestos"
        titulo="Actualizar Impuestos"
        mensaje="¿Desea actualizar los impuestos en el ticket?"
        icon="aprobar"
        @accion="setImpuestos"
        />
        <modal ref="modalAsignarOperadorLogistico" titulo="Asignar entrega al operador logístico" center="true" no-aceptar adicional="Asignar" @adicional="asignar">
            <div class="row justify-content-center">
                <div class="col-8">
                    <p>¿Desea asignar este pedido al operador logístico?</p>
                </div>
            </div>
        </modal>
        <modal ref="modalDesvincularOperadorLogistico" titulo="Desvincular pedido" no-aceptar adicional="Desvincular" @adicional="desvincular">
            <div class="row mx-0 py-4">
                <p class="col-12 text-general f-15 text-center">
                    ¿Desea desvincular este pedido de las <br /> entregas del operador logístico?
                </p>
            </div>
        </modal>
        <modal-guias ref="refOpenModalGuias" />
    </section>
</template>

<script>
import Pedidos from '~/services/pedidos/pedidos-admin'
import {mapGetters} from 'vuex'
export default {
    components: {
        modalEditarPedido: () => import('./partials/modalEditarPedido'),
        modalOtorgarCredito: () => import('./partials/modalOtorgarCredito'),
        modalGuias: () => import('./partials/modalGuiasEnvio.vue'),
    },
    data(){
        return{
            ver_mas:false,
            deliverys:[],
            empacadores:[],
            condiciones:[],
            showLineaTiempo: false,
            impuestos:0,
            checkNomina: 1,
            formaPago:'',
            editNumFactura: null,
            metodosPago2:[],
            paqueteria:{}
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
            pedido: 'pedidos/pedidos_admin/pedido',
            cliente: 'pedidos/pedidos_admin/cliente',
            historial: 'pedidos/pedidos_admin/historial',
            resumen: 'pedidos/pedidos_admin/resumen',
            entrega: 'pedidos/pedidos_admin/entrega',
            convenio: 'pedidos/pedidos_admin/convenio',
            empacador: 'pedidos/pedidos_admin/empacador',
            metodosPago:'pedidos/pedidos_admin/metodosPago',
            pagoOnline:'pedidos/pedidos_admin/pago_online',
            banderaMetodosPago:'pedidos/pedidos_admin/banderaMetodosPago',
            stateFormOrder:'pedidos/pedidos_admin/showFormOrder',

        }),
        datosDelivery(){
            let info = {}
            info =this.deliverys.find(o=>o.id == this.pedido.id_user_delivery)

            if(info === undefined) return {foto:null}

            return info
        },
        nombreEstado(){
            if(!this.historial.length) return ''
            let filtered = this.historial.filter(el => el.activo)
            if(!filtered.length) return ''

            return filtered[filtered.length - 1]["content"] ?? '';
        },
    },
    watch:{
        id_pedido(value){
            if(value){
                this.listarDelivery()
                this.listarCondiciones()
                //this.setMetodoPago()

            }
        },
        pedido(){
            this.impuestos = this.pedido.impuestos
        },
        banderaMetodosPago: {
            handler(value, oldValue){
                if(value){
                    this.setMetodoPago()
                }
            },
            immediate: true
        }
    },
    methods: {
        goToFormOrder(){
            this.$store.commit('pedidos/pedidos_admin/setStateFormOrder', !this.stateFormOrder)
        },
        openModalguias(items){
            this.$refs.refOpenModalGuias.toggle(items)
        },
        async listarEmpacadores(tipo){
            try {

                if(tipo == false)return // cuando cierra el select

                if(!this.$can('botones_abastecimiento_gestionar_picking')) return // Cuando no tiene permiso

                if(tipo == 'asignado'){
                    const form = {id:null, nombre:null,foto:null}
                    this.$store.commit('pedidos/pedidos_admin/set_empacador',form)
                }

                if(this.empacadores.length > 0) return // Cuando ya tiene datos la lista

                const {data} = await Pedidos.getEmpacadores(this.pedido.id_cedis)
                this.empacadores = data.empacadores
            } catch (e){
                this.error_catch(e)
            }
        },
        async listarDelivery(){
            try {
                const {data} = await Pedidos.get_delivery(this.id_pedido)
                this.deliverys = data.domiciliarios
            } catch (e){
                this.error_catch(e)
            }
        },
        async listarCondiciones(){
            try {
                const {data} = await Pedidos.get_condiciones(this.id_pedido)
                this.condiciones = data.condiciones
            } catch (e){
                this.error_catch(e)
            }
        },
        verInstrucciones(){
            this.$refs.modalVerInstrucciones.toggle(this.pedido)
        },
        otorgarCredito(){
            this.$refs.OtorgarCredito.toggle();
        },
        mostar_credito(){
            //this.$store.dispatch('pedidos/pedidos_admin/pedido_credito', this.id_pedido)
            this.pedido.pago_estado = 2
        },
        async cambiarDestinatario(val){
            try {
                let model = {
                    cliente_entrega_directa:val
                }
                const {data} = await Pedidos.cambiar_destino_pedido(this.id_pedido,model)
                this.notificacion('Mensaje','Se ha cambiado el Destinatario del pedido','success')
            } catch (e){
                this.error_catch(e)
            }

        },
        async nueva_direccion(val){
            try {
                const {data} = await Pedidos.nueva_direccion(this.id_pedido,val)
            } catch (error){
                this.error_catch(e)
            }
        },
        fun_caracteres(cadena){
            if(!cadena) return
            let total = cadena.length
            if(this.ver_mas){
                return this.formatearTextoHtml(cadena.substr(0,total))
            }
            return this.formatearTextoHtml(cadena.substr(0,300))
        },
        async setDelivery(value){
            try {
                const {data} = await Pedidos.set_delivery(this.id_pedido,value)
                this.notificacion('Mensaje','Se ha actualizado el domiciliario','success')
            } catch (e){
                this.error_catch(e)
            }
        },
        async setEmpacador(value){
            try {

                const {data} = await Pedidos.setEmpacador(this.id_pedido,value)
                this.notificacion('Mensaje','Se ha actualizado el Picking del pedido','success')

                const info = this.empacadores.find(o=>o.id == value)
                this.$store.commit('pedidos/pedidos_admin/set_empacador',info)


            } catch (e){
                this.error_catch(e)
            }
        },
        async setCondicion(value){
            try {
                const {data} = await Pedidos.set_condicion(this.id_pedido,value)
                this.notificacion('Mensaje','Se ha actualizado la condición de la alianza','success')
                this.$store.dispatch('pedidos/pedidos_admin/pedidos_resumenes', Number(this.id_pedido))
            } catch (e){
                this.error_catch(e)
            }
        },
        openModalSetImpuestos(){
            this.$refs.modalSetImpuestos.toggle()
        },
        async setImpuestos(res){
            try {
                if(!res){
                    this.$refs.modalSetImpuestos.toggle()
                    return
                }
                const {data} = await Pedidos.set_impuestos(this.id_pedido,this.impuestos)
                this.notificacion('Mensaje','Se ha actualizado la aplicacion de impuestos al pedido','success')
                this.$store.dispatch('pedidos/pedidos_admin/ver_pedido', Number(this.id_pedido))
                this.$refs.modalSetImpuestos.toggle()
                //this.$store.dispatch('pedidos/pedidos_admin/pedidos_resumenes', Number(this.id_pedido))
            } catch (e){
                this.error_catch(e)
            }
        },
        confirmarAsignar(){
            this.$refs.modalAsignarOperadorLogistico.toggle()
        },
        async asignar(){
            try {
                let form = {
                    id_pedido: this.id_pedido,
                };
                const {data} = await Pedidos.asignarOperadorLogistico(form)
                this.pedido.operador_logistico_pedido = data.operador_logistico;
                this.notificacion('Mensaje', data.mensaje, data.tipo)
                this.$refs.modalAsignarOperadorLogistico.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        confirmarDesvincular(){
            this.$refs.modalDesvincularOperadorLogistico.toggle()
        },
        async desvincular(){
            try {
                let form = {
                    id_pedido: this.id_pedido,
                };
                const {data} = await Pedidos.desvincularOperadorLogistico(form)
                this.pedido.operador_logistico_pedido = data.operador_logistico;
                this.notificacion('Mensaje', data.mensaje, data.tipo)
                this.$refs.modalDesvincularOperadorLogistico.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        comentarioOperadorAsignado(){
            switch (this.pedido.operador_logistico_pedido){
            case 1:
                return "Solicitud enviada, esperando respuesta";
            case 2:
                return "Entrega cancelada";
            case 3:
                return "Solicitud rechazada";
            case 4:
                return "Solicitud aceptada";
            default:
                return ""
            }
        },
        async checkConvenioNomina(event){
            try {
                if(event){
                    this.convenio.convenio_efectivo = event;

                    const metodo = this.metodosPago2[0]
                    this.pedido.pago_teorico = metodo.tipo
                    this.pedido.id_metodo_pago = metodo.id_metodo_pago

                    await this.putFormaPagoPedido()
                    this.setMetodoPago();
                    this.notificacion('Mensaje', 'Convenio actualizado', 'success');
                    return
                }
                const form = {
                    id_convenio: this.convenio.id_convenio,
                };
                const {data} = await Pedidos.putConvenioEfectivo(this.pedido.id_pedido, form)
                this.convenio.convenio_efectivo = data.efectivo;
                if(data.efectivo == false){
                    this.pedido.pago_teorico = 5
                    this.pedido.id_metodo_pago = null
                    await this.putFormaPagoPedido()
                    this.setMetodoPago();
                    this.notificacion('Mensaje', 'Convenio actualizado', 'success');
                }else{
                    this.notificacion('Mensaje', 'No cuenta con cupo suficiente', 'error');
                }


            } catch (e){
                this.error_catch(e)
            }
        },
        async putFormaPagoPedido(metodo = false){
            try {

                if(metodo){
                    this.pedido.pago_teorico = metodo.tipo
                    this.pedido.id_metodo_pago = metodo.id_metodo_pago
                }

                const form = {
                    formaPago: this.pedido.pago_teorico,
                    id_metodo_pago: this.pedido.id_metodo_pago
                };
                const {data} = await Pedidos.putFormaPagoPedido(this.pedido.id, form)
                this.notificacion('Mensaje', 'Forma pago actualizada', 'success');
            } catch (e){
                this.error_catch(e)
            }
        },
        async editNumeroFactura(){
            try {
                const payload = {
                    numFactura: this.pedido.numero_factura,
                }
                const { data } = await Pedidos.putNumeroFactura(this.pedido.id, payload)
                this.notificacion('Mensaje', 'Número de factura actualizado', 'success');
                this.editNumFactura = false
            } catch (e){
                this.error_catch(e)
            }
        },
        setMetodoPago(){
            this.$store.commit('pedidos/pedidos_admin/setBanderaMetodosPagos',false)
            for (const mp of this.metodosPago){
                if(mp.tipo == 4) mp.disabled = true

                if(this.convenio == null && mp.tipo == 5){
                    mp.disabled = true
                }

                if(this.convenio != null && this.convenio.convenio_efectivo === true){
                    if(mp.tipo == 5 || mp.tipo == 4){
                        mp.disabled = true
                    }else{
                        mp.disabled = false
                    }
                }

                if(this.convenio != null && this.convenio.convenio_efectivo === false){
                    mp.disabled = true
                }

                if(this.pedido.id_metodo_pago != null && this.pedido.id_metodo_pago == mp.id_metodo_pago){
                    this.formaPago = mp.nombre
                }

                if(this.pedido.id_metodo_pago == null && this.pedido.pago_teorico == mp.tipo){
                    this.formaPago = mp.nombre
                }

                if(this.pedido.pago_teorico == 4){
                    mp.disabled = true
                }

            }
            this.metodosPago2 = this.metodosPago
        }
    }
}
</script>

<style lang="scss" scoped>
.br-12{
  border-radius: 12px;
}
.header-credito{
  border-radius: 12px 12px 0 0px;
  height: 50px;
}
.borders{
  border-left: 1px solid #5D5D5D59;
  border-right: 1px solid #5D5D5D59;
  border-bottom: 1px solid #5D5D5D59;
  border-radius: 0px 0px 12px 12px;
}
.icon-action::before{
    display: contents !important;
}
.text-red{ color: #FF2525; }
.text-green{ color: #28D07B; }
.br-t-12 {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.ticket {
  border: 1px solid #28d07b;
  background-color: #28d07b33;
  .total-value {
    color: #28d07b;
    font-weight: 600;
  }
}
</style>
